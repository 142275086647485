import React, { Component } from 'react';
import Helmet from "react-helmet";
import config from "data/SiteConfig";
import Layout from 'layout';
import TopBanner from 'partials/topBanner';
import Tippy from '@tippy.js/react'
import {graphql} from 'gatsby';
// import 'tippy.js/dist/tippy.css'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as featureActions from 'redux/actions/feature';

const tippyProps = {
	placement: "top",
	animateFill: false,
	animation: 'shift-toward',
	boundary: 'window',
	interactive: true
}

class Feature extends Component {

	componentDidMount(){
		const {pageContext , featureActions} = this.props;
		featureActions.change_tab(pageContext.pages);
	}

	componentWillReceiveProps() {
		if(typeof window !== 'undefined') {
			window.addEventListener('popstate', (event) => {
				window.location.reload();
			});
		}
	}

	handleClick = (data) =>{
		this.props.featureActions.change_tab(data);
		if(typeof window !== 'undefined') {
			window.history.pushState(null, document.title, `${window.location.origin}/guide/feature/${data}`);
		}
	}

	generateTab = (pages) =>{
		const {fitur} = this.props.data;
		return fitur.edges.map((data,x)=>
			<li key={x} className={data.node.slug === pages ? `active`: ``} onClick={()=>this.handleClick(data.node.slug)}>
				{data.node.name}
			</li>
		)
	}

	generateTabContent = (pages) =>{
		const {fitur} = this.props.data;
		return fitur.edges.map((data,x)=>
			<div key={x} className={`page animated fadeIn fast ${(data.node.slug === pages ? `active`: ``)}`}>
				<div className="title">
					<span>{data.node.name}</span>
				</div>
				<div className="detail_tab">
					<div className="info">
						<p>{data.node.description}</p>
					</div>
					<div className={`detail_tab__feature`}>
						{(data.node.item_list.data.map((datas,y)=>
							<Tippy key={y} {...tippyProps} content={
								<div className="hover">
									<div className="hover_box">
										<p>{datas.description}</p>
									</div>
								</div>
							}>
								<div key={y} className="detail_tab__feature_list">
									<img src={datas.image} alt="" className="img-fluid"/>
									<p>{datas.name}</p>
								</div>
							</Tippy>
						))}
					</div>
				</div>
			</div>
		)
	}
		
	render() {
		const {tab} = this.props.feature;
		return (
			<Layout>
				<Helmet title={"Feature - " + config.siteTitle} />
				<div id="FeatureLayout" className="content-container">
					<TopBanner 
						image={require('assets/img/fitur/BG_Fitur_Unik.jpg')} 
						firstString="Skill Unik"
						secondString="Pemain"
						subtitle="Atur Strategimu sesuai"
						description="dengan gaya bermainmu"
					/>
					<div className="content">
						<div className="feature_tab tabs">
							<ul className="tabs__title">
								{this.generateTab(tab)}
							</ul>
							<div className="tabs__content">
								{this.generateTabContent(tab)}
							</div>
						</div>    
					</div>
				</div>
			</Layout>
		);
	}
}

function mapStateToProps(state) {
	return { feature: state.feature }
}

function mapDispatchToProps(dispatch) {
	return {
		featureActions: bindActionCreators(featureActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Feature);

/* eslint no-undef: "off" */
export const pageQuery = graphql`
{
	fitur: allDirectusFeature {
	  edges {
		node {
		  directusId
		  name
		  description
		  slug
		  item_list { 
			  data {
			  name
			  description
			  image
			}
		  }
		}
	  }
	}
  }
  
`;